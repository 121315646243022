<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ContestManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">{{ $t('labels.add_new_result_record') }}</div>
										<div class="content__title--subtext">{{ $t('labels.new_record') }}</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST" @submit.prevent="onSubmit">
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.contest_condition') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.condition_desc
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="condition_desc"
															:placeholder="$t('headers.contest_condition')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.condition_desc"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.condition_desc"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.contest_date') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.date_at
																? 'input--error'
																: '',
														]"
													>
														<input
															type="date"
															class="input-linck"
															required=""
															name="date_at"
															:placeholder="$t('headers.contest_date')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.date_at"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.date_at"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.info_object') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.object_desc
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="object_desc"
															:placeholder="$t('headers.info_object')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.object_desc"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.object_desc"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('headers.info_contest_winner') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.winner_desc
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="winner_desc"
															:placeholder="$t('headers.info_contest_winner')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.winner_desc"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.winner_desc"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">
												{{ $t('headers.signers_liabilities') }}
											</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.commitments
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="commitments"
															:placeholder="$t('headers.signers_liabilities')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.commitments"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.commitments"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input form__block--input"
														:class="[
															errorMessage.messages && errorMessage.messages.document
																? 'input--error'
																: '',
														]"
													>
														<label class="form__line--file" :class="fileDocuments.fileClass">
															<input
																type="file"
																ref="fileDocuments"
																accept=".pdf,.png,.jpg,.doc,.docx,.excel"
																v-on:change="handleFileUpload()"
															/>
															<div class="form__file form__file--center">
																<div class="form__file--icon">
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 16.2A4.5 4.5 0 0017.5 8h-1.8A7 7 0 104 14.9M12 12v9M8 17l4 4 4-4" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																</div>
																<div class="form__file--text" v-if="fileDocuments.fileName">
																	{{ fileDocuments.fileName }}
																</div>
																<div class="form__file--text" v-else>
																	{{ $t('labels.upload_pdf') }}
																</div>
															</div>
														</label>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.document"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.document"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div class="input__row">
												<button class="input-btn form__line--btn">
													<!-- v-if="applications.status != 'active' && applications.status != 'deleted'" -->
													{{ $t('button.save') }}
												</button>
											</div>

											<div
												class="form__block--line form__messages"
												v-if="message.status"
												:class="{
													'form__messages--error': message.status != 200,
													'form__messages--success': message.status == 200,
												}"
											>
												<label class="form__block--input">
													<span class="form__block--title">
														{{ message.text }}
													</span>
												</label>
											</div>
											<div
												class="form__block--line form__messages"
												v-if="errorMessage.text"
												:class="{
													'form__messages--error': errorMessage.status != 200,
													'form__messages--success': errorMessage.status == 200,
												}"
											>
												<label class="form__block--input">
													<span class="form__block--title">
														{{ errorMessage.text }}
													</span>
												</label>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api, urlApi } from '@/boot/axios'

export default {
	data() {
		return {
			urlApi: urlApi,

			applications: null,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			blockElemet: null,
			unlockElemet: null,

			fileDocuments: {
				file: null,
				fileName: '',
				fileClass: '',
			},
		}
	},
	methods: {
		handleFileUpload() {
			this.fileDocuments.file = this.$refs.fileDocuments.files[0]
			this.fileDocuments.fileName = this.$refs.fileDocuments.files[0].name
			this.fileDocuments.fileClass = 'add_tourist--success'
		},

		onSubmit(evt) {
			this.errorMessage = {
				status: null,
				messages: null,
				text: null,
			}
			this.message = {
				status: null,
				text: null,
			}
			const formData = new FormData(evt.target)
			if (this.fileDocuments.file) {
				formData.append('document', this.fileDocuments.file)
			}

			api
				.post('contest/create', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
						this.$router.push({ path: '/' + this.$i18n.locale + '/account/contest/' })
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
		},
	},

	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.users_parent'),
			}
		},
		meta: [],
	},
}
</script>
